import Vue from 'vue'
import { ModalPlugin, BootstrapVue } from 'bootstrap-vue'
import VueSimplePrintPlugin from 'vue-simple-print'
import VueLoading from 'vuejs-loading-plugin'
import VueCompositionAPI from '@vue/composition-api'
import VueCurrencyFilter from 'vue-currency-filter'
import Toast from 'vue-toastification'
import VueTheMask from 'vue-the-mask'
import Multiselect from 'vue-multiselect'
import i18n from '@/libs/i18n'
import vSelect from 'vue-select'
import money from 'v-money'
import DataTable from 'laravel-vue-datatable'
import VueConfirmDialog from 'vue-confirm-dialog'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components' // For form-wizard

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

import 'vue-toastification/dist/index.css'

require('vue-multiselect/dist/vue-multiselect.min.css')

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  precision: 2,
})
Vue.use(DataTable)
Vue.use(VueSimplePrintPlugin)
// eslint-disable-next-line import/no-extraneous-dependencies
Vue.use(require('vue-moment'))

Vue.use(VueTheMask)
Vue.use(BootstrapVue)

Vue.use(VueLoading, {
  dark: true, // default false
  text: 'Carregando...', // default 'Loading'
  loading: false, // default false
  background: 'rgb(255,255,255)', // set custom background
  classes: ['myclass'], // array, object or string
})

// register directive v-money and component <money>
Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  precision: 2,
  masked: false,
})

Vue.use(Toast, {})
Vue.component('v-select', vSelect)
Vue.component('multiselect', Multiselect)

// BSV Plugin Registration
Vue.use(ModalPlugin)
Vue.use(VueCurrencyFilter,
  {
    symbol: 'R$',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true,
    avoidEmptyDecimals: undefined,
  })
// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
