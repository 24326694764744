import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'http://127.0.0.1:8000/api/painel/',
  // baseURL: 'https://bromildo.devnx.com.br/api/painel/',
  baseURL: 'https://phpstack-666249-2855315.cloudwaysapps.com/api/painel/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
