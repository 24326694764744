export default {
  // Endpoints Integrated
  // loginEndpoint: 'https://bromildo.devnx.com.br/api/painel/auth/login',
  // registerEndpoint: 'https://bromildo.devnx.com.br/api/painel/auth/register',
  // refreshEndpoint: 'https://bromildo.devnx.com.br/api/painel/auth/refresh-token',
  // logoutEndpoint: 'https://bromildo.devnx.com.br/api/painel/auth/logout',

  // // Endpoints No Integrated
  loginEndpoint: 'https://phpstack-666249-2855315.cloudwaysapps.com/api/painel/auth/login',
  registerEndpoint: 'https://phpstack-666249-2855315.cloudwaysapps.com/api/painel/auth/register',
  refreshEndpoint: 'https://phpstack-666249-2855315.cloudwaysapps.com/api/painel/auth/refresh-token',
  logoutEndpoint: 'https://phpstack-666249-2855315.cloudwaysapps.com/api/painel/auth/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
