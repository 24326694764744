export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/inicio',
    name: 'app-inicio',
    component: () => import('@/views/app/inicio'),
    meta: { action: 'painel' },
  },
  {
    path: '/configuracao',
    name: 'app-configuracao',
    component: () => import('@/views/app/configuracao/index.vue'),
    meta: { action: 'painel' },
  },
  {
    path: '/pdv',
    name: 'app-pdv',
    component: () => import('@/views/app/pdv'),
    meta: {
      action: 'painel',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/clientes',
    name: 'app-clientes',
    component: () => import('@/views/app/clientes'),
    meta: { action: 'admin' },
  },
  {
    path: '/clientes/add',
    name: 'app-clientes-adicionar',
    component: () => import('@/views/app/clientes/adicionarCliente.vue'),
    meta: { action: 'admin' },
  },
  {
    path: '/clientes/adicionar/:id',
    name: 'app-addclientes',
    component: () => import('@/views/app/clientes/addclientes.vue'),
    meta: { action: 'admin' },
  },
  {
    path: '/fornecedor',
    name: 'app-suppliers',
    component: () => import('@/views/app/suppliers'),
    meta: { action: 'admin' },
  },
  {
    path: '/fornecedor/adicionar',
    name: 'app-supplier-create',
    component: () => import('@/views/app/suppliers/create.vue'),
    meta: { action: 'admin' },
  },
  {
    path: '/fornecedor/:id',
    name: 'app-supplier-edit',
    component: () => import('@/views/app/suppliers/edit.vue'),
    meta: { action: 'admin' },
  },
  {
    path: '/transportadores',
    name: 'app-conveyors',
    component: () => import('@/views/app/conveyors'),
    meta: { action: 'admin' },
  },
  {
    path: '/transportadores/adicionar',
    name: 'app-conveyors-create',
    component: () => import('@/views/app/conveyors/create.vue'),
    meta: { action: 'admin' },
  },
  {
    path: '/transportadores/:id',
    name: 'app-conveyors-edit',
    component: () => import('@/views/app/conveyors/edit.vue'),
    meta: { action: 'admin' },
  },
  {
    path: '/produtos',
    name: 'app-produtos',
    component: () => import('@/views/app/produtos'),
    meta: { action: 'admin' },
  },
  {
    path: '/produtos/adicionar',
    name: 'app-addprodutos',
    component: () => import('@/views/app/produtos/addprodutos.vue'),
    meta: { action: 'admin' },
  },
  {
    path: '/produtos/editar/:id',
    name: 'app-editprod',
    component: () => import('@/views/app/produtos/editprodutos.vue'),
    meta: { action: 'admin' },
  },
  //
  {
    path: '/pedidos',
    name: 'app-pedidos',
    component: () => import('@/views/app/pedidos'),
    meta: { action: 'caixa' },
  },
  //
  {
    path: '/pedidos/detalhes',
    name: 'app-pedidosdetalhes',
    component: () => import('@/views/app/pedidos/pedidos-detalhes.vue'),
    meta: { action: 'admin' },
  },
  {
    path: '/usuarios',
    name: 'app-usuario-ver',
    component: () => import('@/views/app/usuarios/usuarios.vue'),
    meta: { action: 'admin' },
  },
  {
    path: '/usuarios/adicionar',
    name: 'app-usuario-adicionar',
    component: () => import('@/views/app/usuarios/adicionarUsuarios.vue'),
    meta: { action: 'admin' },
  },
  {
    path: '/usuarios/editar',
    name: 'app-usuario-editar',
    component: () => import('@/views/app/usuarios/editUser.vue'),
    meta: { action: 'admin' },
  },
  {
    path: '/mensagens',
    name: 'app-mensagens',
    component: () => import('@/views/app/mensagens/mensagens.vue'),
    meta: { action: 'admin' },
  },
  {
    path: '/carteira',
    name: 'app-carteira',
    component: () => import('@/views/app/carteira/carteira.vue'),
    meta: { action: 'admin' },
  },
  {
    path: '/orcamento',
    name: 'app-orcamento',
    component: () => import('@/views/app/orcamento/orcamento.vue'),
    meta: { action: 'painel' },
  },
  {
    path: '/pedido-pago',
    name: 'pedido-pago',
    component: () => import('@/views/app/pedidos/pedidos-pagos.vue'),
    meta: { action: 'admin' },
  },
]
